.Home-pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.Home-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 36px 0px;
  z-index: 1;
}

.Home-thu {
  flex: 0 0 50%;
}
.Home-thu img {
  display: block;
  height: 400px;
  margin: 0 auto;
}

.Home-rightContainer {
  flex: 0 0 50%;
}

.Home-rightContainer img {
  height: 200px;
  width: auto;
}

.Home-ducky {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: fit-content;
  z-index: 2;
}

.Home-ducky img {
  height: 50px;
  margin: 5px 0px;
}

.Home-ducky img:hover {
  cursor: pointer;
}
