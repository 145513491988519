#contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  float: left;
  padding: 0;
  list-style: none;
}

#contact li a {
  display: block;
  padding: 8px 45px;
  text-decoration: none;
  font-weight: bold;
  color: var(--red);
}
