:root {
  --red: #ff5757;
  --cream: #f6f3e4;
  --champagne: #f0ebd1;
  --white: #faf8f0;
  --black: #1b1e2e;
  --gray: #7f7f7f;
}

.Page-body {
  height: 100%;
  overflow: hidden;
}

.Page-loaderContainer {
  display: flex;
  flex: 4;
  background-image: linear-gradient(white, var(--cream));
}

.Page-loader {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
}

.Page-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  width: 100%;
}

.Page-left {
  flex: 1;
  height: 100%;
  text-align: center;
  padding: 10vh 36px;
}

.Page-descriptionContainer {
  height: 100px;
  text-align: left;
  overflow-y: auto;
  margin-bottom: 20px;
}

.Page-right {
  flex: 4;
  background-image: linear-gradient(white, var(--cream));
  height: 100vh;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.Page-rightContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.Page-tabContainer {
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow-y: auto;
}

.Page-tab {
  font-weight: bold;
  text-decoration: none;
  margin: 8px auto;
  padding: 4px 10px;
  max-height: 100px;
}

/* Animated Tab Buttons */
/* Source: Thar Buttons from https://codepen.io/sazzad/pen/yNNNJG */

.animatedButton:link,
.animatedButton:visited {
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 14px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 0.08em;
  border-radius: 0;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.animatedButton:link:after,
.animatedButton:visited:after {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.75s ease 0s;
  -moz-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
}

.animatedButton {
  cursor: pointer;
  display: block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
}
.animatedButton:hover {
  color: #1b1e2e !important;
  background-color: transparent;
}
.animatedButton:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.animatedButton:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: var(--cream);
  border-radius: 20px;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
}

/* Scrollbar */
/* Source: https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--champagne);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
