.ProjectCard-container {
  height: auto;
  max-width: 60%;
  border-radius: 5px;
  display: flex;
  margin: 24px;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
}

.ProjectCard-image {
  display: block;
  overflow: auto;
  margin: 0 auto;
}

.ProjectCard-postImageContent {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 900px;
}

@media only screen and (max-width: 400px) {
  .ProjectCard-img {
    max-height: 100px;
    max-width: 100%;
    object-fit: contain;
  }
}

.ProjectCard-img {
  max-height: 400px;
  max-width: 100%;
  object-fit: contain;
}

.ProjectCard-description {
  margin-bottom: 5px;
}

.ProjectCard-tags {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ProjectCard-nameAndLink {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ProjectCard-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ProjectCard-link {
  margin: 3px;
}
